import React from 'react';
import ReactDOM from 'react-dom/client';

// import "./styles.css";
import "bulma"
import { BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import * as Components from "./components";
import * as Pages from "./pages";

function WillItSwim() {
  return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Pages.Home />} />
				<Route path="*" element={<Pages.FourZeroFour />} />
			</Routes>
		</BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WillItSwim />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
