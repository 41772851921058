
const config = {

	// Text to be displayed as a descriptor of the swimming status
	blurbs:{
		negFlow:{
			negLevel: "Totally Dry. Maybe a hike?",
			lowLevel: "Dry. Possibly a puddle. Hit the pool?",
			medLevel: "A stagnant pool. Swimming could be gross.",
			hiLevel: "Has water but stagnant. Swimming could be gross.",
			extLevel: "Full, but stagnant. Swimming could be gross."
		},
		lowFlow:{
			negLevel: "Totally Dry. Maybe a hike?",
			lowLevel: "Trickling pools. Not very good to swim.",
			medLevel: "Water with a little flow. Could be ok to swim.",
			hiLevel: "Good amount of water with a trickle. Ok to swim.",
			extLevel: "Lots of water water with a trickle. Ok to swim.",
		},
		medFlow:{
			negLevel: "Flow with no water level. Probably sensor error.",
			lowLevel: "Descent flow with a little water. Ok to swim.",
			medLevel: "Good amount of water and some flow. Good to swim.",
			hiLevel: "Lots of water with a good amount of flow. Great to swim.",
			extLevel: "High water with flow. Great to swim.",
		},
		hiFlow:{
			negLevel: "Flow with no water level. Probably sensor error.",
			lowLevel: "High flow with some water. Great to swim.",
			medLevel: "Great amount of flow. Great to swim. Tube the greenbelt?",
			hiLevel: "High level and flow. Great to swim.",
			extLevel: "Lots of water and flow. Could be intense.",
		},
		extFlow:{
			negLevel: "Flow with no water level. Probably sensor error.",
			lowLevel: "Very high flow. Could be dangerous to swim.",
			medLevel: "Very high flow. Could be dangerous to swim.",
			hiLevel: "Very high flow. Could be dangerous to swim.",
			extLevel: "Very high flow. Could be dangerous to swim.",
		}
	},

	// Color to be displayed as the gauge colors
	colorStates:{
		negFlow:{
			negLevel: "gray",
			lowLevel: "grayStripes",
			medLevel: "grayStripes",
			hiLevel: "grayStripes",
			extLevel: "grayStripes",
		},
		lowFlow:{
			negLevel: "gray",
			lowLevel: "yellow",
			medLevel: "yellow",
			hiLevel: "yellow",
			extLevel: "yellow",
		},
		medFlow:{
			negLevel: "gray",
			lowLevel: "green",
			medLevel: "green",
			hiLevel: "green",
			extLevel: "orange",
		},
		hiFlow:{
			negLevel: "gray",
			lowLevel: "green",
			medLevel: "green",
			hiLevel: "green",
			extLevel: "orange",
		},
		extFlow:{
			negLevel: "danger",
			lowLevel: "danger",
			medLevel: "danger",
			hiLevel: "danger",
			extLevel: "danger",
		}
	},
	
	// Max value in ft3/s to fall in the flow category
	flowMaxes:{
		negFlow: 5,
		lowFlow: 20,
		medFlow: 40,
		hiFlow: 90,
		extFlow: 999999999.0,
	},

	// Max values in ft to fall in the level category
	levelMaxes:{
		negLevel: 1.0,
		lowLevel: 2.0,
		medLevel: 3.0,
		hiLevel: 5.0,
		extLevel: 9999999999.0,
	},


	sites: {
		lostCreek: {
			code: "08155240",
			name: "Lost Creek",
		},
		loop360: {
			code: "08155300",
			name: "Loop 360",
		},
		bartonSpgs: {
			code: "08155400",
			name: "Barton Springs",
		},
	},

	parameters: {
		flow: {
			code: "00060",
			name: "Flow",
			unitsText: "ft3/s",
			unitsComp: <span>ft<sup>3</sup>/s</span>,
		},
		level: {
			code: "00065",
			name: "Level",
			unitsText: "ft",
			unitsComp: <span>ft</span>,
		}
	},

	colors: {
		green: {
			id: "greenGradient",
			type: "gradient",
			start: "#4ade80",
			end: "#22c55e",
		},
		yellow: {
			id: "yellowGradient",
			type: "gradient",
			start: "#fde047",
			end: "#facc15",
		},
		gray: {
			id: "grayGradient",
			type: "gradient",
			start: "#aaaaaa",
			end: "#cccccc",
		},
		orange: {
			id: "orangeGradient",
			type: "gradient",
			start: "#ed820e",
			end: "#fc6a03",
		},
		danger: {
			id: "dangerStripes",
			type: "stripes",
			start: "#ffffff",
			end: "#ff0000",
		},
		brown: {
			id: "brownStripes",
			type: "stripes",
			start: "#65350f",
			end: "#9a7b4f",
		},
		grayStripes:{
			id: "grayStripes",
		}
	},

	styleColors: {
		green: "#9dd3b9",
		blue: "#2e8a97",
		clay: "#c3724d",
		brown: "#563128",
		lightOrange: "#eeae2c",
		yellow: "#fffac3",
		darkOrange: "#bc5626",
		lightYellow: "#f5f5f0",
	}
}

export default config;