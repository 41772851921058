import React from "react";
import StatGauge from "./stat_gauge";
import LevelGauge from "./level_gauge";
import config from "../config.js";
import "../CSS/styles.css";

function StatBlock({props}){

	
	const siteKey = props.siteKey;
	const siteData = props.siteData;
	const siteName = config.sites[siteKey].name;

	if (! siteData.hasData){
		return (
			<div className="box is-flex is-flex-direction-column is-justify-content-center is-align-content-center" style={{height: '16rem', width: '16rem'}}>
				<div className="subtitle is-5 has-text-centered">
					{siteName}
				</div>
				<div className="subtitle is-5 has-text-centered">
					Loading...
				</div>
			</div>
		)
	}

	// props.siteData.flow.average = 40;
	// props.siteData.level.average = 4;

	let flowKey = "negFlow";
	for (const [key, data] of Object.entries(config.flowMaxes)){
		// console.log("flow: " + props.siteData.flow.average.toString() + ", Data: " + data);
		if (props.siteData.flow.average < data){
			flowKey = key;
			break;
		}
	}

	let levelKey = "negLevel";
	for (const [key, data] of Object.entries(config.levelMaxes)){
		// console.log("Level: " + props.siteData.level.average.toString() + ", Data: " + data);
		if (props.siteData.level.average < data){
			levelKey = key;
			break;
		}
	}


	// console.log(JSON.stringify(config.colorStates, null, 2));

	return (
		<div className="box is-flex is-flex-direction-column is-justify-content-center is-align-content-center" border-width="10px"  style={{
			height: '17rem', 
			width: '17rem', 
			// padding: '1rem',
			marginBottom: '1rem'
			}}>
				<div className="subtitle is-4 has-text-centered my-0">
					{siteName}
				</div>
				<div className="columns is-mobile my-0 py-1">
					<div className="column">
						<LevelGauge height="112" 
							value={props.siteData.level.average} 
							max={4}
							color={config.colors[config.colorStates[flowKey][levelKey]]}
						/>
						<div className="subtitle is-6 has-text-centered">
							{siteData.level.average.toString()} ft
						</div>
					</div>
					<div className="column is-flex-direction-column is-justify-content-center">
						<StatGauge 
							value={props.siteData.flow.average}
							color={config.colors[config.colorStates[flowKey][levelKey]]}
						/>
						<div className="subtitle is-6 has-text-centered">
							{props.siteData.flow.average} ft<sup>3</sup>/s
						</div>
					</div>
				</div>
				<div className="subtitle is-6 has-text-centered" style={{margin:"0rem", padding: "0rem"}}>
					{config.blurbs[flowKey][levelKey]}
				</div>
		</div>
	);

} 

export default StatBlock;