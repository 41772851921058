import React, { useMemo } from "react";
// import { Spring } from "react-spring";
import config from "../config.js";
import {ReactComponent as ColorSvg} from "../colors.svg"

function LevelGauge({
	height = 100, 
	value = 0, 
	min = 0, 
	max = 100,
	color = config.colors.gray
}) {


	if (value > max){
		value = max;
	} else if (value < min){
		value = min;
	}

	if (! height){
		height = "100";
	}

	const viewBoxDims = {
		width: 70,
		height: 170,
	}

	const frameDims = {
		width: 56,
		height: 156,
		radius: 8,
	}

	const dims = {
		physical:{
			width: (parseFloat(height) * viewBoxDims.width / viewBoxDims.height).toString(),
			height: height,
		},
		cord:{
			width: viewBoxDims.width.toString(),
			height: viewBoxDims.height.toString(),
		},
		frame:{
			width: frameDims.width.toString(),
			height: frameDims.height.toString(),
			xOffset: ((viewBoxDims.width - frameDims.width) / 2).toString(),
			yOffset: ((viewBoxDims.height - frameDims.height) / 2).toString(),
			radius: frameDims.radius.toString(),
		},
		gauge:{
			yOffset: (((viewBoxDims.height - frameDims.height) / 2) + ((1 - (value - min) / (max - min)) * frameDims.height)).toString(),
			height: ((((value - min) / (max - min))) * frameDims.height).toString(),
		}

	}


	// console.log(min.toString() + " " + max.toString() + " " + value.toString());
	// console.log("baseOffset: " + ((viewBoxDims.height - frameDims.height) / 2).toString());
	// console.log("valueOffset: " + (((value - min) / (max - min)) * frameDims.height).toString());
	// console.log("Multiplier: " + ((value - min) / (max - min)).toString());

	// console.log(JSON.stringify(dims, null, 2));


	return ( 
		<svg 
			width={dims.physical.width} 
			height={dims.physical.height} 
			version="1.1" 
			viewBox={"0 0 " +  dims.cord.width + " " + dims.cord.height} 
			xmlns="http://www.w3.org/2000/svg">
			<defs>
				<clipPath id="clip_path">
				<rect x={dims.frame.xOffset} y={dims.frame.yOffset} width={dims.frame.width} height={dims.frame.height} ry={dims.frame.radius} fill="transparent"/>
				</clipPath>
			</defs>
			<rect  x={dims.frame.xOffset} y={dims.frame.yOffset} width={dims.frame.width} height={dims.frame.height} ry={dims.frame.radius} fill="#e2e2e2"/>
			<rect  x={dims.frame.xOffset} y={dims.gauge.yOffset} width={dims.frame.width} height={dims.gauge.height} ry="0.0" clipPath="url(#clip_path)" fill={"url(#" + color.id + ")"} fillOpacity="1.0"/>
		</svg>
	);


}

export default LevelGauge;
