import React from "react";
import config from "../config.js";

function FourZeroFour() {
  return (
		<div style={{height:"100vh", margin: '0rem', padding:"2rem", backgroundColor:config.styleColors.lightYellow}}>
			<div className="title is-2">
				404
			</div>
			<div className="subtitle is-">
				Are you lost?
			</div>
		</div>
  );
}

export default FourZeroFour;
