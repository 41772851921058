import React, { useMemo } from "react";
import { useGauge } from "use-gauge";
import config from "../config.js";

const START_ANGLE = 45;
const END_ANGLE = 315;

function StatGauge({
	value = 0,
	color = config.colors.gray
}) {
  // value = 20;
	if (value > 100){
		value = 100;
	}


  const gauge = useGauge({
    domain: [0, 100],
    startAngle: START_ANGLE,
    endAngle: END_ANGLE,
    numTicks: 21,
    diameter: 135
  });

  const needle = gauge.getNeedleProps({
    value,
    baseRadius: 2,
    tipRadius: 2
  });

  return (
    <div className="container is-flex align-items-center justify-content-center">
      <svg className="w-full overflow-visible p-1" style={{overflow: 'visible'}} {...gauge.getSVGProps()}>
        <defs>
					<linearGradient
						id="greenGradient"
						x1="0%"
						x2="100%"
						y1="0%"
						y2="100%"
					>
						<stop offset="0%" stopColor="#4ade80"></stop>
						<stop offset="100%" stopColor="#22c55e"></stop>
					</linearGradient>

					<linearGradient
						id="yellowGradient"
						x1="0%"
						x2="100%"
						y1="0%"
						y2="100%"
					>
						<stop offset="0%" stopColor="#fde047"></stop>
						<stop offset="100%" stopColor="#facc15"></stop>
					</linearGradient>

					<linearGradient
						id="orangeGradient"
						x1="0%"
						x2="100%"
						y1="0%"
						y2="100%"
					>
						<stop offset="0%" stopColor="#ed820e"></stop>
						<stop offset="100%" stopColor="#fc6a03"></stop>
					</linearGradient>

					<linearGradient
						id="grayGradient"
						x1="0%"
						x2="100%"
						y1="0%"
						y2="100%"
					>
						<stop offset="0%" stopColor="#aaaaaa"></stop>
						<stop offset="100%" stopColor="#cccccc"></stop>
					</linearGradient>

					<pattern id="dangerStripes" patternUnits="userSpaceOnUse" width="20" height="20" viewBox="0 0 10 10" patternTransform="rotate(45)">
						<rect width="5" height="10" fill="#ff8888"/>
						<rect x="5" width="5" height="10" fill="#ff0000" />
					</pattern>
					<pattern id="grayStripes" patternUnits="userSpaceOnUse" width="20" height="20" viewBox="0 0 10 10" patternTransform="rotate(45)">
						<rect width="5" height="10" fill="#999999"/>
						<rect x="5" width="5" height="10" fill="#bbbbbb" />
					</pattern>
					<pattern id="brownStripes" patternUnits="userSpaceOnUse" width="20" height="20" viewBox="0 0 10 10" patternTransform="rotate(45)">
						<rect width="5" height="10" fill="#65350f"/>
						<rect x="5" width="5" height="10" fill="#9a7b4f" />
					</pattern>
        </defs>
        <g id="arcs">
          <path
            {...gauge.getArcProps({
              offset: -26,
              startAngle: START_ANGLE,
              endAngle: END_ANGLE
            })}
            fill="none"
            strokeWidth={34}
						style={{stroke:'#e5e7eb'}}
          />
          <path
            {...gauge.getArcProps({
              offset: -26,
              startAngle: START_ANGLE,
              endAngle: gauge.valueToAngle(value)
            })}
            strokeWidth={34}
            fill="transparent"
            stroke={value < 0.2 ? "transparent" : "url(#" + color.id + ")"}
          />
        </g>
        <g id="ticks">
          {gauge.ticks.map((angle) => {
            const asValue = gauge.angleToValue(angle);
            const showText = asValue === 5 || asValue === 20 || asValue === 50 || asValue === 90;

            return (

              <React.Fragment key={`tick-group-${angle}`}>
                <line
                  style={{stroke:'#6b7280'}}
                  {...gauge.getTickProps({ angle, length: showText ? 12 : 6 })}
                />
                {/* <text
                  className="text-sm fill-gray-500 font-medium"
                  {...gauge.getLabelProps({ angle, offset: -30 })}
                >
                  {gauge.angleToValue(angle)}
                </text> */}
              </React.Fragment>
            );
          })}
        </g>
        <g id="needle">
          <circle className="fill-white" {...needle.base} r={5} />

          <line
            style={{stroke:'#374151'}}
            strokeLinecap="round"
            strokeWidth={6}
            x1={needle.base.cx}
            x2={needle.tip.cx}
            y1={needle.base.cy}
            y2={needle.tip.cy}
          />
        </g>
      </svg>
    </div>
  );
}

export default StatGauge;

{/* <line
style={{stroke:'#6b7280'}}
strokeWidth={4}
{...gauge.getTickProps({ angle, length: showText ? 12 : 6 })}
/> */}