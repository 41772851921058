import React from "react";
import * as Components from "../components";
import { ReactComponent as MapSvg } from "../images/map_stylized_smoothed_opti.svg"
import "bulma-divider/dist/css/bulma-divider.min.css";
import { useGauge } from "use-gauge";
import config from "../config.js";
import "../CSS/styles.css"
import { ReactComponent as TitleSvg } from "../images/title_block_opti.svg";



function Home() {

	// Parameters to graph
	const sites = ["lostCreek", "loop360", "bartonSprings"];
	const parameters = ["flow", "height"];
	const gauges = ["primary", "secondary"];

	// Code lookup dictionaries
	const siteCodes = {
		"lostCreek": "08155240",
		"loop360": "08155300",
		"bartonSprings": "08155400"
	};
	const parameterCodes = {
		"flow": "00060",
		"height": "00065"
	};
	const siteTitles = {
		"lostCreek": "Lost Creek",
		"loop360": "Loop 360",
		"bartonSprings": "Barton Springs"
	}
	

	const [waterData, setWaterData] = React.useState(()=>{

		// Generate the initial state
		const sitesJson = {};
		for (const [siteKey, siteValue] of Object.entries(config.sites)){
			sitesJson[siteKey] = {}
			sitesJson[siteKey].hasData = false;
		}
		return (sitesJson);
	});

	const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

	const [svgHeight, setSvgHeight] = React.useState(() => {
		return getSvgHeight();
	}, []);

	const [svgOverWidth, setSvgOverWidth] = React.useState(()=>{
		return getSvgOverWidth;
	}, []);

  React.useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
			setSvgHeight(getSvgHeight());
			setSvgOverWidth(getSvgOverWidth());
			// console.log(getSvgOverWidth());
		}

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

	React.useEffect(()=>{
		// console.log("Running use affect on startup...");
		getCreekDataNew();
		// getCreekData();
		setSvgHeight(getSvgHeight());
		setSvgOverWidth(getSvgOverWidth());
	}, []);

	function getSvgHeight(){
		const titleElement = document.getElementById("title-block");
		let titleElementHeight = titleElement ? titleElement.offsetHeight : 0;
		return window.innerHeight - titleElementHeight - (2*16);
	}

	function getSvgOverWidth(){
		const windowWidth = window.innerWidth;
		const svgHeight = getSvgHeight();
		const svgWidthOverHeight = 13 / 10;
		const svgWidth = svgHeight * svgWidthOverHeight + (4* 16);
		let svgOverWidth = svgWidth / windowWidth;
		if (svgOverWidth > 1){
			svgOverWidth = 1;
		}
		return svgOverWidth;
	}

	async function getCreekDataNew(){

		const sitesData = {};

		// Create a string of the parameter codes for the URL
		let parameters = [];
		for (const [paramKey, paramValue] of Object.entries(config.parameters)){
			parameters.push(paramValue.code);
		}
		const paramString = parameters.join(",");

		// Iterate through the sites in config.sites
		for (const [siteKey, siteValue] of Object.entries(config.sites)){
			// console.log("Key : " + siteKey + " value: " + siteValue);
			sitesData[siteKey] = {};
			
			// Generate the fetch URL and fetch
			const url = 'https://waterservices.usgs.gov/nwis/iv/?format=json&indent=on&sites=' + siteValue.code + '&parameterCd=' + paramString + '&siteStatus=all';
			let response = await fetch(url, {method: 'GET'});
			const responseJson = await response.json();

			// Iterate through parameters
			let paramIndex = 0;
			for (const [paramKey, paramData] of Object.entries(config.parameters)){
				let pullDataSuccessFlag = true;
				let gaugeIndex = 0;
				sitesData[siteKey][paramKey] = {};
				sitesData[siteKey][paramKey].values = [];
				// Try pulling data from the primary then secondary, etc sensors until it fails
				while (pullDataSuccessFlag){
					try{
						sitesData[siteKey][paramKey].values.push(responseJson["value"]["timeSeries"][paramIndex]["values"][gaugeIndex]["value"][0]["value"]);
					} catch {
						pullDataSuccessFlag = false;
					}
					gaugeIndex += 1;
				}
				// average the gauges and put them in .average
				let total = 0;
				for (let i = 0; i < sitesData[siteKey][paramKey].values.length; i++){
					total = total + parseFloat(sitesData[siteKey][paramKey].values[i]);
					sitesData[siteKey].hasData = true;
				}
				sitesData[siteKey][paramKey].average = (total / sitesData[siteKey][paramKey].values.length).toFixed(2);
				paramIndex += 1;
			}
		}
	
		// console.log(JSON.stringify(sitesData, null, 2));
		setWaterData(sitesData);
		return
	}

  return (
		<div style={{margin: '0rem', backgroundColor:config.styleColors.lightYellow}}>
			<div id="title-block" style={{
				textAlign: 'center', 
				backgroundColor:config.styleColors.lightYellow,
				margin: '0rem',
				padding: '0.5rem',
				}}>
				{/* <div className="title" style={{margin: '0rem'}}>
					Will It Swim?
				</div> */}
				<TitleSvg width="100%" height="7rem"/>
				{/* <div className="subtitle is-5" style={{marginTop: '0rem'}}>
					Austin Greenbelt Water Status
				</div> */}
			</div>
			<div style={{ position: "relative" }}>
				<MapSvg id="map-svg" width="100%" height={isMobile ? '100%' : `${svgHeight}px`} style={{ position: "relative" }} />
				{isMobile ? (
					<>
						<div className="container is-flex  is-flex-direction-column" >
							<div className="container is-flex is-justify-content-center"  style={{ marginTop: "0.5rem" }}>
								<Components.StatBlock
									props={{
										siteKey: "lostCreek",
										siteData: waterData["lostCreek"],
									}}
								/>
							</div>
							<div className="container is-flex is-justify-content-center" style={{ marginTop: "1rem" }}>
								<Components.StatBlock
									props={{
										siteKey: "loop360",
										siteData: waterData["loop360"],
									}}
								/>
							</div>
							<div className="container is-flex is-justify-content-center" style={{ marginTop: "1rem" }}>
								<Components.StatBlock
									props={{
										siteKey: "bartonSpgs",
										siteData: waterData["bartonSpgs"],
									}}
								/>
							</div>
						</div>
					</>
				) : (
					<>
						<div
							style={{
								position: "absolute",
								top: "55%",
								left: `${((1 - svgOverWidth) * 100 / 2) + (svgOverWidth * 11)}%`,
								transform: "translate(-50%, -50%)",
							}}
						>
							<Components.StatBlock
								props={{
									siteKey: "lostCreek",
									siteData: waterData["lostCreek"],
								}}
							/>
						</div>
						<div
							style={{
								position: "absolute",
								top: "30%",
								left: `${((1 - svgOverWidth) * 100 / 2) + (svgOverWidth * 54)}%`,
								transform: "translate(-50%, -50%)",
							}}
						>
							<Components.StatBlock
								props={{
									siteKey: "loop360",
									siteData: waterData["loop360"],
								}}
							/>
						</div>
						<div
							style={{
								position: "absolute",
								top: "70%",
								left: `${((1 - svgOverWidth) * 100 / 2) + (svgOverWidth * 89)}%`,
								transform: "translate(-50%, -50%)",
							}}
						>
							<Components.StatBlock
								props={{
									siteKey: "bartonSpgs",
									siteData: waterData["bartonSpgs"],
								}}
							/>
						</div>
					</>
				)}
			</div>
		</div>
  );
}


export default Home;
